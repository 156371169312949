import {
  list as getAddressesInfo,
  update as updateMemberInfo,
  create as createMemberInfo,
} from '@wix/ambassador-addresses-api-v1-address/http';
import {ambassadorWithHeaders} from '../utils/ambassador.utils';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {MemberAddressesInfoModel} from '../models/MemberAddressesInfo.model';
import {ADDRESSES_APP_DEFINITION_ID} from '@wix/wixstores-client-core';
import {getFieldMasksFromAddress, mapToUpdateMemberAddressRequest} from '../utils/billingDetails.utils';
import {ApiAddressFragment, FullAddressContactDetailsFragment} from '../../gql/graphql';
import {AddressWithContactModel} from '../models/AddressWithContact.model';
import {MA_APP_IDS, AppDefinitionId} from '@wix/members-area-app-definitions';

export class MemberService {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  public readonly currency?: string;
  public addressesInfo: MemberAddressesInfoModel = {
    addresses: [],
  };
  public isAddressesAppInstalled!: boolean;
  public isMemberAreaAppInstalled!: boolean;
  public currentUserEmail: string = '';

  constructor({siteStore, flowAPI, currency}: {flowAPI: ControllerFlowAPI; siteStore: SiteStore; currency?: string}) {
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.currency = currency;
  }

  public isMember(): boolean {
    return Boolean(this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn);
  }

  public async fetchAddressesInfo(): Promise<void> {
    const {data} = await ambassadorWithHeaders(getAddressesInfo({}), this.siteStore, this.flowAPI, this.currency);
    this.addressesInfo = new MemberAddressesInfoModel(data);
  }

  public async setIsMemberAreaAppInstalled(): Promise<void> {
    const {site} = this.flowAPI.controllerConfig.wixCodeApi;
    const isMemberAreaAppInstalledV1 = await site.isAppSectionInstalled({
      sectionId: MA_APP_IDS.MY_ACCOUNT.pageId,
      appDefinitionId: MA_APP_IDS.MY_ACCOUNT.appDefinitionId,
    });
    const isMemberAreaAppInstalledV2 = await site.isAppSectionInstalled({
      sectionId: 'member_page',
      appDefinitionId: AppDefinitionId.ProfilePageBob,
    });

    this.isMemberAreaAppInstalled = isMemberAreaAppInstalledV1 || isMemberAreaAppInstalledV2;
  }
  public async setIsAddressesAppInstalled(): Promise<void> {
    this.isAddressesAppInstalled = await this.flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      sectionId: 'my_addresses',
      appDefinitionId: ADDRESSES_APP_DEFINITION_ID,
    });
  }

  public async getCurrentUserEmail(): Promise<void> {
    this.currentUserEmail = await this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.getEmail();
  }

  public getAddressByAddressesServiceId(addressesServiceId?: string): AddressWithContactModel | undefined {
    return this.addressesInfo.addresses.find((address) => address.addressesServiceId === addressesServiceId);
  }

  public async updateMemberInfo(
    contactDetails: FullAddressContactDetailsFragment,
    address: ApiAddressFragment,
    addressesServiceId: string,
    setAsDefault: boolean
  ): Promise<void> {
    const mappedMemberDetailsInfo = mapToUpdateMemberAddressRequest(contactDetails, address, addressesServiceId);

    await ambassadorWithHeaders(
      updateMemberInfo({
        address: mappedMemberDetailsInfo,
        fieldMask: getFieldMasksFromAddress(mappedMemberDetailsInfo),
        setAsDefault,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
    await this.fetchAddressesInfo();
  }

  public async createMemberInfo(
    contactDetails: FullAddressContactDetailsFragment,
    address: ApiAddressFragment,
    isSetAsDefaultChecked: boolean
  ): Promise<string> {
    const mappedMemberDetailsInfo = mapToUpdateMemberAddressRequest(contactDetails, address);

    const {data} = await ambassadorWithHeaders(
      createMemberInfo({
        address: mappedMemberDetailsInfo,
        setAsDefault: isSetAsDefaultChecked,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
    await this.fetchAddressesInfo();
    return data.id!;
  }
}
